.swipe {
    position: relative;
    background: linear-gradient(90deg, #FF05E6 0.06%, #4400D6 98.7%);
    width: 170px;
    height: 50px;
    color: #fff;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    letter-spacing: 2px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.35);
    border-left: 0.5px solid rgba(0, 0, 0, 0.35);
    padding-left: 40px;
    cursor: pointer;
    transition: 0.35s ease;
    font-weight: bold;
  }
  
  .swipe:hover {
    padding-left: 0;
    padding-right: 40px;
    color: #fff;
    font-weight: bold;
  }
  
  .container {
    position: absolute;
    left: 5px;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    transition: 0.35s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF05E6;
  }
  
  .swipe:hover .container {
    left: calc(100% - 55px);
    color: white;
    background: #FF05E6;
  }
  